<template>
  <div class="columns is-multiline stadistics" v-if="!loading">
    <div class="column is-12" v-if="hasData() && isShowCharts">
      <h1><span v-if="formattedDateRange">{{ formattedDateRange }}</span></h1>
    </div>
    <div v-else><h1>Debes ingresar un rango de fechas para mostrar los gráficos</h1></div>
    <!-- <div class="column is-12" v-if="hasSeriesData(series) && isShowCharts">
      <div class="box">
        <p class="subtitle">Productos cargados</p>
        <apexchart id="productChart" class="chart" :width="'100%'" type="line" :options="options" :series="series"></apexchart>
      </div>
    </div> -->
    <!-- <div class="column is-12" v-if="hasSeriesData(seriesUsers) && isShowCharts">
      <div class="box">
        <p class="subtitle">Usuarios nuevos</p>
        <apexchart id="usersChart" class="chart" :width="'100%'" type="line" :options="optionsUsers" :series="seriesUsers"></apexchart>
      </div>
    </div> -->
    <div class="column is-12" v-if="hasSeriesData(seriesProductTimelines) && isShowCharts">
      <div class="box">
        <p class="subtitle">Total de Celulares Comprados por Givu</p>
        <apexchart id="productTimelinesChart" class="chart" :width="'100%'" type="line" :options="optionsProductTimelines" :series="seriesProductTimelines"></apexchart>
      </div>
    </div>
    <div class="column is-12" v-if="hasSeriesData(seriesTransactions) && isShowCharts">
      <div class="box">
        <p class="subtitle">Total de Celulares Vendidos por Givu</p>
        <apexchart id="transactionsChart" class="chart" :width="'100%'" type="line" :options="optionsTransactions" :series="seriesTransactions"></apexchart>
      </div>
    </div>
    <div class="column is-12" v-if="hasSeriesData(seriesStatus) && isShowCharts">
      <div class="box">
        <p class="subtitle">Productos de the giver</p>
        <apexchart id="statusChart" class="chart" :width="'100%'" type="line" :options="optionsStatus" :series="seriesStatus"></apexchart>
      </div>
    </div>
  </div>
  <div v-else>
    <loading-component />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'charts',
  data () {
    return {
      loading: false,
      labels: [],
      labelsUsers: [],
      labelsStatus: [],
      labelsTransactions: [],
      labelsProductTimelines: [],
      values: [],
      valuesUsers: [],
      valuesStatus: [],
      valuesTransactions: [],
      valuesProductTimelines: [],
      options: {
        chart: {
          id: 'productChart',
          width: '75%'
        },
        xaxis: {
          categories: []
        },
        colors: ['#DA6255']
      },
      series: [{}],
      // Usuarios
      optionsUsers: {
        chart: {
          id: 'usersChart',
          width: '75%'
        },
        xaxis: {
          categories: []
        },
        colors: ['#9A2EFE']
      },
      seriesUsers: [{}],
      // Estado de productos
      optionsStatus: {
        chart: {
          id: 'statusChart',
          width: '75%'
        },
        xaxis: {
          categories: []
        },
        colors: ['#00C8FF']
      },
      seriesStatus: [{}],
      // Transacciones de vendedores
      optionsTransactions: {
        chart: {
          id: 'transactionsChart',
          width: '75%'
        },
        xaxis: {
          categories: []
        },
        colors: ['#FFAB00']
      },
      seriesTransactions: [{}],
      // Líneas de productos con fecha de cierre
      optionsProductTimelines: {
        chart: {
          id: 'productTimelinesChart',
          width: '75%'
        },
        xaxis: {
          categories: []
        },
        colors: ['#00FF96']
      },
      seriesProductTimelines: [{}],
      startDate: null,
      endDate: null,
      isShowCharts: true
    }
  },
  props: {
    dateSegmentationGraph: {
      type: Object,
      default: () => {}
    },
    shouldShowCharts: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formattedDateRange () {
      if (this.startDate && this.endDate) {
        return `Rango de fecha: ${moment(this.startDate).format('DD-MM-YYYY')} / ${moment(this.endDate).format('DD-MM-YYYY')}`
      }
      return null
    }
  },
  methods: {
    ...mapActions(['getDashboardChartProductsSegmentation', 'getDashboardChartProducts']),
    getInfo () {
      this.getDashboardChartProducts()
        .then(result => {
          const labels = result.productDataChart.reduce((accum, element) => {
            accum.push(`${element.year} - ${element.month}`)
            return accum
          }, [])
          const values = result.productDataChart.reduce((accum, element) => {
            accum.push(element.total)
            return accum
          }, [])

          const labelsUsers = result.usersDataChart.reduce((accum, element) => {
            accum.push(`${element.year} - ${element.month}`)
            return accum
          }, [])
          const valuesUsers = result.usersDataChart.reduce((accum, element) => {
            accum.push(element.total)
            return accum
          }, [])

          const labelsStatus = result.countUserProductsWithStatus.reduce((accum, element) => {
            accum.push(`${element.year} - ${element.month}`)
            return accum
          }, [])
          const valuesStatus = result.countUserProductsWithStatus.reduce((accum, element) => {
            accum.push(element.total)
            return accum
          }, [])

          // Datos de transacciones de vendedores
          const labelsTransactions = result.countSellerTransactions.reduce((accum, element) => {
            accum.push(`${element.year} - ${element.month}`)
            return accum
          }, [])
          const valuesTransactions = result.countSellerTransactions.reduce((accum, element) => {
            accum.push(element.total)
            return accum
          }, [])

          // Datos de líneas de productos con fecha de cierre
          const labelsProductTimelines = result.countProductTimelinesWithCloseDate.reduce((accum, element) => {
            accum.push(`${element.year} - ${element.month}`)
            return accum
          }, [])
          const valuesProductTimelines = result.countProductTimelinesWithCloseDate.reduce((accum, element) => {
            accum.push(element.total)
            return accum
          }, [])

          this.values = values
          this.labels = labels
          this.valuesUsers = valuesUsers
          this.labelsUsers = labelsUsers
          this.valuesStatus = valuesStatus
          this.labelsStatus = labelsStatus
          this.valuesTransactions = valuesTransactions
          this.labelsTransactions = labelsTransactions
          this.valuesProductTimelines = valuesProductTimelines
          this.labelsProductTimelines = labelsProductTimelines
          this.loading = false
        })
    },
    getDasboardChartSegmentation (newData) {
      this.loading = true
      let empty = false
      if (newData && newData.__ob__ !== undefined) {
        if (Object.keys(newData).length === 0) {
          empty = true
        }
      }
      if (empty) {
        this.startDate = null
        this.endDate = null
        this.isShowCharts = true
        this.getInfo()
      } else {
        const dateObj = {
          start: moment(newData?.start).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          end: moment(newData?.end).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        }
        this.startDate = newData?.start
        this.endDate = newData?.end
        this.getDashboardChartProductsSegmentation(dateObj)
          .then(result => {
            const labels = result.productDataChart.reduce((accum, element) => {
              accum.push(`${element.day}`)
              return accum
            }, [])
            const values = result.productDataChart.reduce((accum, element) => {
              accum.push(element.total)
              return accum
            }, [])

            const labelsUsers = result.usersDataChart.reduce((accum, element) => {
              accum.push(`${element.day}`)
              return accum
            }, [])
            const valuesUsers = result.usersDataChart.reduce((accum, element) => {
              accum.push(element.total)
              return accum
            }, [])

            // Datos de estado de productos
            const labelsStatus = result.countUserProductsWithStatus.reduce((accum, element) => {
              accum.push(`${element.day}`)
              return accum
            }, [])
            const valuesStatus = result.countUserProductsWithStatus.reduce((accum, element) => {
              accum.push(element.total)
              return accum
            }, [])

            // Datos de transacciones de vendedores
            const labelsTransactions = result.countSellerTransactions.reduce((accum, element) => {
              accum.push(`${element.day}`)
              return accum
            }, [])
            const valuesTransactions = result.countSellerTransactions.reduce((accum, element) => {
              accum.push(element.total)
              return accum
            }, [])

            // Datos de líneas de productos con fecha de cierre
            const labelsProductTimelines = result.countProductTimelinesWithCloseDate.reduce((accum, element) => {
              accum.push(`${element.day}`)
              return accum
            }, [])
            const valuesProductTimelines = result.countProductTimelinesWithCloseDate.reduce((accum, element) => {
              accum.push(element.total)
              return accum
            }, [])

            this.values = values
            this.labels = labels
            this.valuesUsers = valuesUsers
            this.labelsUsers = labelsUsers
            this.valuesStatus = valuesStatus
            this.labelsStatus = labelsStatus
            this.valuesTransactions = valuesTransactions
            this.labelsTransactions = labelsTransactions
            this.valuesProductTimelines = valuesProductTimelines
            this.labelsProductTimelines = labelsProductTimelines
            this.loading = false
          })

        if (moment(dateObj.start).isSame(dateObj.end, 'day')) {
          this.isShowCharts = false
        } else {
          this.isShowCharts = true
        }
      }
    },
    hasSeriesData (data) {
      return Array.isArray(data) && data.length > 0
    },
    hasData () {
      return this.series?.[0]?.data?.length !== 0 && this.seriesUsers?.[0]?.data?.length !== 0
    }
  },
  components: {
    LoadingComponent: () => import('@/components/ui/Loading')
  },
  watch: {
    dateSegmentationGraph (newData) {
      this.getDasboardChartSegmentation(newData)
    },
    labels () {
      this.options = { ...this.options, xaxis: { categories: this.labels } }
    },
    values () {
      this.series = [{ data: this.values, name: 'Productos' }]
    },
    labelsUsers () {
      this.optionsUsers = { ...this.optionsUsers, xaxis: { categories: this.labelsUsers } }
    },
    valuesUsers () {
      this.seriesUsers = [{ data: this.valuesUsers, name: 'Usuarios' }]
    },
    labelsStatus () {
      this.optionsStatus = { ...this.optionsStatus, xaxis: { categories: this.labelsStatus } }
    },
    valuesStatus () {
      this.seriesStatus = [{ data: this.valuesStatus, name: 'Estado de productos' }]
    },
    labelsTransactions () {
      this.optionsTransactions = { ...this.optionsTransactions, xaxis: { categories: this.labelsTransactions } }
    },
    valuesTransactions () {
      this.seriesTransactions = [{ data: this.valuesTransactions, name: 'Transacciones' }]
    },
    labelsProductTimelines () {
      this.optionsProductTimelines = { ...this.optionsProductTimelines, xaxis: { categories: this.labelsProductTimelines } }
    },
    valuesProductTimelines () {
      this.seriesProductTimelines = [{ data: this.valuesProductTimelines, name: 'Líneas de productos' }]
    }
  },
  mounted () {
    // this.getInfo()
    this.getDasboardChartSegmentation(this.dateSegmentationGraph)
  }
}
</script>
